<template>
  <div v-if="!$apollo.loading" class="h-100 d-flex">
    <b-container class="bg-white flex-fill p-2">
      <router-link
        :to="{ name: 'account' }"
        class="btn btn-outline-primary btn-sm"
      >
        <font-awesome-icon icon="arrow-left" fixed-width />
      </router-link>
      <b-list-group v-if="credits.length">
        <b-list-group-item
          v-for="credit in credits"
          :key="credit.id"
          class="border-bottom m-0 rounded-0 row no-gutters d-flex p-2"
        >
          <div class="d-flex col-8 flex-column">
            <h3>
              <b-badge variant="info">
                {{ credit.label }}
              </b-badge>
            </h3>
            <h5 v-if="credit.venue && credit.venue.id">
              <router-link
                :to="{ name: 'credit-venue', params: { id: credit.venue.id } }"
              >
                {{ credit.venue.name }}
              </router-link>
            </h5>
            <h5 v-else>
              {{ credit.creditable.creditableName }}
            </h5>

            <small>{{ credit.createdAt.diffForHumans }}</small>
            <div v-for="(historical, i) in credit.historicals" :key="i">
              <small v-if="historical.newValue === 'sending'" class="d-block">
                <font-awesome-icon icon="paper-plane" fixed-width />
                Note envoyé le {{ historical.createdAt.localeFormat }}
              </small>
              <small
                v-if="historical.newValue === 'authorised'"
                class="d-block"
              >
                <font-awesome-icon icon="check-circle" fixed-width />
                Traité le {{ historical.createdAt.localeFormat }}
              </small>
              <small v-if="historical.newValue === 'captured'" class="d-block">
                <font-awesome-icon icon="money-bill" fixed-width />
                Remboursé le {{ historical.createdAt.localeFormat }}
              </small>
              <small v-if="historical.newValue === 'refused'" class="d-block">
                <font-awesome-icon icon="minus-circle" fixed-width />
                Refusé le {{ historical.createdAt.localeFormat }}
              </small>
            </div>
          </div>
          <div
            class="ml-auto d-flex align-items-center justify-content-end col-4"
          >
            <h4 class="text-primary text-right mb-0">
              {{ credit.amount }}
            </h4>
          </div>
          <div v-if="credit.needNote" class="col-12 mt-2">
            <div v-if="uploading"><loader size="1x" />Envoie en cours</div>
            <b-form-file
              v-show="!uploading"
              v-model="file"
              :state="Boolean(file)"
              placeholder="Envoyer ma note"
              @input="input(credit, ...arguments)"
            />
          </div>
        </b-list-group-item>
      </b-list-group>
      <div v-else class="p-2">
        <b-alert show variant="info"> Pas de crédit </b-alert>
      </div>
      <portal to="content">
        <transition name="swipe">
          <router-view />
        </transition>
      </portal>
    </b-container>
  </div>
  <div v-else class="h-100 d-flex align-items-center justify-content-center">
    <font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
  </div>
</template>
<script>
import CREDITS from "@/graphql/auth/credits.gql"
import STORE_RECEIPT from "@/graphql/mutations/StoreReceipt.gql"
import Loader from "@/components/Loader"
export default {
  components: {
    Loader
  },
  data() {
    return {
      file: null,
      uploading: false
    }
  },
  apollo: {
    credits: {
      query: CREDITS,
      client: "auth",
      variables() {
        return {
          status: this.$route.meta.status
        }
      }
    }
  },
  methods: {
    input(credit) {
      this.uploading = true
      const file = this.file
      this.$apollo.mutate({
        mutation: STORE_RECEIPT,
        client: "auth",
        variables: {
          file,
          venueId: credit.venue.id,
          userVenueDealId: credit.userVenueDeal.id
        },
        update: (store, { data: { storeReceipt } }) => {
          this.uploading = false
          credit.needNote = false
          credit.historicals.push({
            newValue: "sending",
            createdAt: storeReceipt.createdAt
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.list-group-item {
  border: 0;
}
.router-view {
  top: 0;
  left: 0;
  z-index: 15;
}
</style>
